@tailwind base;
@tailwind components;
@tailwind utilities;

body,html {
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: "Roboto", system-ui;
  font-weight: 500;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:placeholder-shown + label {
  left: 0.75rem;
  margin-top: 12px;
  color: #9ca3af;
}

input:not(:placeholder-shown) + label,
input:focus + label {
  top: -0.6rem; 
  left: 0.5rem;
  margin-top: 0;
  background: #fff;
  color: #6b7280;
}

select:placeholder-shown + label {
  left: 0.75rem;
  margin-top: 12px;
  color: #9ca3af;
}

select:not(:placeholder-shown) + label,
select:focus + label {
  top: -0.6rem; 
  left: 0.5rem;
  margin-top: 0;
  background: #fff;
  color: #6b7280;
}

textarea:placeholder-shown + label {
  left: 0.75rem;
  margin-top: 12px;
  color: #9ca3af;
}

textarea:not(:placeholder-shown) + label,
textarea:focus + label {
  top: -0.6rem; 
  left: 0.5rem;
  margin-top: 0;
  background: #fff;
  color: #6b7280;
}

tr, th, td{
  padding: 4px;
  font-size: 14px;
}

th{
  padding: 12px;
  color: #fff;
  font-weight: 400;
  text-align: left;
}

.slick-slide > div{
  margin: 0 10px;
}
